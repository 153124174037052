/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function InfoTramites() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/sections/Medellin02.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Información - Trámites y Servicios</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{ paddingTop: "50px", paddingBottom: "30px" }}
        >
          <p
            className="my-4"
            style={{
              textAlign: "justify",
              fontWeight: 500,
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Los trámites que adelantan las Notarías son los siguientes:
          </p>
          <ol
            style={{
              textAlign: "justify",
              fontWeight: 500,
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            <li>
              <p>
                Recibir, extender y autorizar las declaraciones que conforme a
                las Leyes requieran escritura pública y aquellas a las cuales
                los interesados quieran revestir de esta solemnidad.
              </p>
            </li>
            <li>
              <p>
                Autorizar el reconocimiento espontáneo de documentos privados
              </p>
            </li>
            <li>
              <p>
                Dar testimonio de la autenticidad de firmas de funcionarios o
                particulares y de otros Notarios que las tengan registradas ante
                ellos.
              </p>
            </li>
            <li>
              <p>
                Dar fe de la correspondencia o identidad que exista entre un
                documento que tenga a la vista y su copia mecánica o literal.
              </p>
            </li>
            <li>
              <p>
                Acreditar la existencia de las personas naturales y expedir la
                correspondiente fe de vida
              </p>
            </li>
            <li>
              <p>
                Recibir y guardar dentro del protocolo los documentos o
                actuaciones que la Ley o el Juez ordenen protocolizar o que los
                interesados quieran proteger de esta manera.
              </p>
            </li>
            <li>
              <p>
                Expedir copias o certificaciones según el caso, de los
                documentos que reposen en sus archivos.{" "}
              </p>
            </li>
            <li>
              <p>
                Dar testimonio escrito con fines jurídico - probatorios de los
                hechos percibidos por ellos dentro del ejercicio de sus
                funciones y de que no haya quedado dato formal en sus archivos.
              </p>
            </li>
            <li>
              <p>
                Intervenir en el otorgamiento, extensión y autorización de los
                testamentos solemnes que conforme a la Ley civil deban otorgarse
                ante ellos.
              </p>
            </li>
            <li>
              <p>
                Practicar apertura y publicación de los testamentos cerrados.
                (...)
              </p>
            </li>
            <li>
              <p>
                Llevar el registro del estado civil de las personas en los
                casos, por los sistemas y con las formalidades prescritos en la
                Ley.
              </p>
            </li>
            <li>
              <p>Las demás funciones que les señalen las Leyes. </p>
            </li>
          </ol>
          <br/>
          <br/>
          <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>           
            <h2
            className="my-4"
            style={{ fontFamily: "'Noto Sans SC', sans-serif" }}
            >
              Procedimientos y Protocolos de Atención
            </h2>
          </p>
          <p>
            <a
              style={{
                textDecoration: "none",
                fontFamily: "'Noto Sans SC', sans-serif",
                color:'black'
              }}
              href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"
              target="_blank"
            >
              <u>
                PROTOCOLO DE BIOSEGURIDAD PARA NOTARIAS UCNC.pdf
              </u>
            </a>
          </p>

          <h2
            className="my-4"
            style={{ fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Formatos y Formularios
          </h2>
          <center className="mt-5">
            <a
              href={"https://drive.google.com/file/d/1gHkUipxjtuJsflbsxF89JYme36wD8uyU/view?usp=sharing"}
              style={{ textDecoration: "none !important" }}
              target="_blank"
            >
              <img
                alt="Minutas de Compraventa"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}
              ></img>
              <h6 className="my-3">Minuta de compraventa</h6>
            </a>
          </center>
          <center className="mt-5">
            <a
              href={"https://drive.google.com/file/d/1jq4sk28lYGQw699S4zBpPLjnnbujG-l2/view?usp=sharing"}
              style={{ textDecoration: "none !important" }}
              target="_blank"
            >
              <img
                alt="Minuta Hipoteca"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}
              ></img>
              <h6 className="my-3">Minuta Hipoteca final</h6>
            </a>
          </center>
        </div>
      </div>
    </div>
  );
}
