/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Organigrama() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/Medellin02.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Organigrama</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>
          <GridContainer className="my-4">
            <GridItem className="col-12">
              <center>
                <img
                    alt="Organigrama de la notaría 14 de Medellin"
                    className="img-rounded img-responsive"
                    style={{width:'90%'}}
                    src={require("assets/img/sections/organigrama.png")}
                  />
              </center>                
            </GridItem>
            <GridItem className="col-12">
              <center className="mt-5">
                <a href={require("assets/pdf/organigrama.pdf")} style={{textDecoration: "none !important"}} target="_blank">
                  <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                  ></img>
                  <h6 className="my-3">Organigrama PDF</h6>
                </a>
              </center>          
            </GridItem>
            <GridItem className="col-12">
              <center className="mt-5">
                <b>Descripción Estructura Orgánica:</b>Jurídica, administración protocolo, autenticación, registro civil, contabilidad, recursos humanos, asesores externos.
              </center>          
            </GridItem>
          </GridContainer>   
        </div>
      </div>
    </div>
  );
}
