import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "componentsPage/Grid/GridContainer.js";
import GridItem from "componentsPage/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office
import office2 from "assets/img/Notaria14med_1.png";
import office1 from "assets/img/Notaria14med_2.png";
import office3 from "assets/img/Notaria14med_3.png";
import office4 from "assets/img/Notaria14med_4.png";
import office5 from "assets/img/Notaria14med_1.png";

const useStyles = makeStyles(officeStyle);

export default function SectionOffice({...rest}) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.office}>
        <div className={classes.container}>
          <GridContainer className={classes.textCenter}>
            <GridItem
              md={8}
              sm={8}
              className={classNames(classes.mrAuto, classes.mlAuto)}
            >
              <h2 className={classes.title}>Nuestra Oficinas</h2>
              <h4 className={classes.description}>
                Tenemos un ambiente adecuado para que usted realice sus trámites.
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <img
                className={classNames(
                  classes.imgRaised,
                  classes.imgFluid,
                  classes.rounded
                )}
                src={office1}
                alt="Sitio de las oficinas de la Notaría 14 de Medellín"
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <img
                className={classNames(
                  classes.imgRaised,
                  classes.imgFluid,
                  classes.rounded
                )}
                src={office2}
                alt="Entrada de las oficinas de la Notaría 14 de Medellín"
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <img
                className={classNames(
                  classes.imgRaised,
                  classes.imgFluid,
                  classes.rounded
                )}
                src={office3}
                alt="Entradas a las oficinas de la Notaría 14 de Medellín"
              />
            </GridItem>
            <GridItem md={6} sm={6}>
              <img
                className={classNames(
                  classes.imgRaised,
                  classes.imgFluid,
                  classes.rounded
                )}
                src={office4}
                alt="Recepción de la Notaría 14 de Medellín"
              />
            </GridItem>
            <GridItem md={6} sm={6}>
              <img
                className={classNames(
                  classes.imgRaised,
                  classes.imgFluid,
                  classes.rounded
                )}
                src={office5}
                alt="Entrada de las oficinas de la Notaría 14 de Medellín"
              />
            </GridItem>
          </GridContainer>
          </div>
      </div>
       </div>
  );
}
