/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Tooltip,
  CardTitle,
  Alert,
  Button,
} from "reactstrap";

import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function MapaSitio() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/Medellin02.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Mapa del sitio</h1>
              <h4>

              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ paddingTop: '50px', paddingBottom: '30px' }}>
          <h4>Mapa del sition</h4>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Col md={12}>
                  <h3><b style={{ color: "#080f61" }}>Páginas</b></h3>
                  <ul>
                    <a href="/page/home"><li >Autenticaciones</li></a>
                    <a href="/page/home#contacts"><li>Contactenos</li></a>
                    <a href="/page/Cotizador2"><li>Cotizador</li></a>
                    <a href="/page/Tramites"><li>Escrituración</li></a>
                    <a href="/page/home"><li>Inicio</li></a>
                    <a href="/page/Tramites"><li>Información Tramites</li></a>
                    <a href="/page/MapaSitio"><li>Mapa del sitio</li></a>
                    <a href="/page/MisionVision"><li>Misión y Visión</li></a>
                    <a href="/page/Notario"><li>Notario</li></a>
                    <a href="/page/home#projects"><li>Noticias</li></a>
                    <a href="/page/Organigrama"><li>Organigrama</li></a>
                    <a href="/page/participa"><li>Participa</li></a>
                    <a href="/page/home#contacts"><li>Registro Civil</li></a>
                    <a href="/page/Tramites"><li>Servicios</li></a>
                    <a href="/page/Tarifas"><li>Tarifas</li></a>
                    <a href="/page/transparencia/informacionPublica"><li>Transparencia</li></a>
                  </ul>
                </Col>
                <Col md={12}>
                  <h3><b style={{ color: "#080f61" }}>Posts por categoría</b></h3>
                  <ul>
                    <a><li >Conocenos</li></a>
                    <ul>
                      <a href="/page/MisionVision"><li>Misión y visión</li></a>
                      <a href="/page/transparencia/funcionesYdeberes"><li>Funciones y deberes</li></a>
                      <a href="/page/Organigrama"><li>Organigrama</li></a>
                      <a href="/page/home#projects"><li>Noticias</li></a>
                      <a href="/page/Notario"><li>Notario</li></a>
                    </ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li >Tramites</li></a>
                    <ul>
                      <a href="/page/Tramites"><li>Información</li></a>
                      <a href="/page/Tramites"><li>Servicios</li></a>
                      <a href="/page/Tarifas"><li>Tarifas</li></a>
                    </ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Información de la Entidad</li></a>
                    <ul><a><li>Mapas y cartas descriptivas de los procesos</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Directorio de entidades</li></a>
                    <ul>
                      <a href="https://www.minjusticia.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                      <a href="https://www.supernotariado.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                      <a href="https://www.registraduria.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                      <a href="https://www.procuraduria.gov.co/portal/"><li>Ministerio de Justicia y del Derecho</li></a>
                      <a href="https://www.vur.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                      <a href="https://www.suin-juriscol.gov.co/"><li>Ministerio de Justicia y del Derecho</li></a>
                    </ul>
                    {/* ------------------------------------------------------------ */}
                    <a ><li>Directorio de agremiaciones, asociaciones y otros grupos de interés</li></a>
                    <ul><a href="/page/transparencia/directorioAgremiaciones"><li>Directorio de agremiaciones, asociaciones y otros grupos de interés</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Servicio al público, normas, formularios y protocolos de atención</li></a>
                    <ul>
                      <a href="/page/Normativa"><li>Normas</li></a>
                      <a href="/page/pqrs"><li>Formularios</li></a>
                      <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"><li>Protocolos de Atención</li></a>
                    </ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Procedimientos que se siguen para tomar decisiones en las diferentes áreas</li></a>
                    <ul><a href="/page/procesos"><li>Procedimientos que se siguen para tomar decisiones en las diferentes áreas</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Información sobre decisiones que pueden afectar al público</li></a>
                    <ul><a><li>Información sobre decisiones que pueden afectar al público</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="/page/transparencia/entesYautoridades"><li>Entes y autoridades que lo vigilan</li></a>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Normativa</li></a>
                    <ul><a href="/page/Normativa"><li>Normativa</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Informes a organismos de inspección, vigilancia y control</li></a>
                    <ul><a><li>Informes a organismos de inspección, vigilancia y control</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Planes de mejoramiento</li></a>
                    <ul><a><li>Planes de Mejoramiento vigentes exigidos por los entes de control o auditoría externos o internos</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a href="#"><li>Informes de gestión, evaluación y auditoría</li></a>
                    <ul><a href="/page/Tramites"><li>Formatos y/o formularios asociados</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li>Instrumentos de gestión de la información</li></a>
                    <ul><a><li>Instrumentos de gestión de la información</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li>Índice de información clasificada y reservada</li></a>
                    <ul><a><li>Índice de información clasificada y reservada</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li>Programa de gestión documental</li></a>
                    <ul><a><li>Programa de gestión documental</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li>Tablas de retención documental</li></a>
                    <ul><a><li>Tablas de retención documental</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li>Información para niños, niñas y adolescentes</li></a>
                    <ul><a><li>Información para niños, niñas y adolescentes</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                    <a><li>Información para Mujeres</li></a>
                    <ul><a><li>Información para Mujeres</li></a></ul>
                    {/* ------------------------------------------------------------ */}
                  </ul>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
