/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import AppsIcon from '@material-ui/icons/Apps';
// core components
import GridContainer from "componentsPage/Grid/GridContainer.js";
import GridItem from "componentsPage/Grid/GridItem.js";
import Card from "componentsPage/Card/Card.js";
import LocationOn from "@material-ui/icons/LocationOn";
import CardBody from "componentsPage/Card/CardBody.js";
import InfoArea from "componentsPage/InfoArea/InfoArea.js";
import Badge from "componentsPage/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import cardProject2 from "assets/img/examples/card-project1.jpg";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import image1 from "assets/img/sections/Fondo1.jpg";
import image2 from "assets/img/bg2.jpg";
import image3 from "assets/img/bg3.jpg";


const useStyles = makeStyles(projectsStyle);


export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className="cd-section" {...rest}>
       {/* Project 4 START */}
      <div className={classes.projects + " " + classes.projects4}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Noticias
              </h2>
              <h5 className={classes.description}>
                En esta sección encontrará novedades de su interes
              </h5>
    
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={5} md={7} className="ml-auto mr-auto">
            <Card>
              <Carousel {...settings}>
                {/* <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h3>
                     
                      Cotizador en Linea
                    </h3>
                    <p>En este módulo podrá realizar las cotizaciones previas a sus escrituras de: Compraventa, Compraventa e hipoteca con particulares y/o entidades financieras, Hipotecas, cancelaciones de hipoteca, matrimonios y sucesiones, entre otras</p>
                    <div className="text-center" >
                      
                      <a className="btn btn-round" style={{
                          backgroundColor: "#379C11",
                          color: "white",
                          borderColor: "#379C11"
                      }}href="/page/Cotizador2" role="button">Ver Cotizador</a>
                    </div>
                  </div>
                </div>
               

                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption" style={{marginTop:'-10px'}}>
                    <h3>
                     
                    Noticia 02
                    </h3>
                    <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                    <div className="text-center" style={{marginRight:'0px'}}>
                      
                      <a className="btn btn-round" style={{
                          backgroundColor: "#379C11",
                          color: "white",
                          borderColor: "#379C11"
                      }}href="#" role="button">Ver Más</a>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption" style={{marginTop:'-10px'}}>
                    <h3>
                     
                      Noticia 03
                    </h3>
                    <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                    <div className="text-center" style={{marginRight:'0px'}}>
                      
                      <a className="btn btn-round" style={{
                          backgroundColor: "#379C11",
                          color: "white",
                          borderColor: "#379C11"
                      }}href="#" role="button">Ver Más</a>
                    </div>
                  </div>
                </div> */}

<div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={image1} className="d-block w-100" alt="Imagen abstracta conformada por triangulos"></img>
      <div className="carousel-caption d-block">
        <h5>Cotizador en Linea</h5>
        <p className="d-none d-md-block">En este módulo podrá realizar las cotizaciones previas a sus escrituras de: Compraventa, Compraventa e hipoteca con particulares y/o entidades financieras, Hipotecas, cancelaciones de hipoteca, matrimonios y sucesiones, entre otras</p>
        <a className="btn btn-round" style={{
                          backgroundColor: "#FFF",
                          color: "black",
                          borderColor: "#FFF"
                      }}href="/page/Cotizador2" role="button">Ver Cotizador</a>
      </div>
    </div>
    <div className="carousel-item">
      <img src={image1} className="d-block w-100" alt="Imagen abstracta conformada por triangulos"></img>
      <div className="carousel-caption d-block">
        <h5>Noticia 02</h5>
        <p className="d-none d-md-block">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
      </div>
    </div> 
  </div>
  <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
                
              </Carousel>
            </Card>
            </GridItem>
            
          </GridContainer>
        </div>
      </div>
      {/* Project 4 END */}
    </div>
  );
}
