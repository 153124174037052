/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Notario() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/sections/Medellin02.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>
                Estudios, investigaciones y otras publicaciones
              </h1>
              <h4></h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{ paddingTop: "50px", paddingBottom: "30px" }}
        >          
          <center><h1>Biografía Notario</h1>
            <p style={{ textAlign: "justify", fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}>
              Mauricio Emilio Amaya Martínez Clark Nació el 12 de febrero de 1958 en Barranquilla, Atlántico, Colombia <br/>
              <b>Experiencia Laboral y Profesional: </b>Juez superior de aduanas. Juez instrucción criminal. Juez décimo
              penal del circuito. Procurador y actualmente Notario 14 de Medellín.
              <br/>
              <b> Electrónico: </b>catorcemedellin@supernotariado.gov.co<br/>
              <b>Telefono Institucional: +57(2)603062</b>.
            </p>
            <p style={{ textAlign: "justify", fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}>
              <b>Formación Académica:</b>
                  <ul>
                    <li> Abogado.</li>
                    <li> Especializacion en derecho penal y ciencias criminologicas.</li>
                    <li> Especialista en derecho constitucional.</li>
                  </ul>
            </p>
            <p style={{ textAlign: "justify", fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}>
              <b>Libros:</b> El proceso penal de justicia y paz desde la intervención del
              ministerio público, su primera edición fue en marzo de 2010.
            </p>
          </center>
        </div>
      </div>
    </div>
  );
}
