/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Procesos() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div> 
      <Parallax image={require("assets/img/sections/Medellin02.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Procesos</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>       
          <GridContainer>
            <GridItem className="col-md-2 col-sm-12">
            <h4 className="text-center">Decreto 960 de 1970</h4>
              <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" 
                role="button" className="btn btn-info" target="_black" aria-disabled="true">
                <b style={{fontSize: "16px"}}>Ver información</b>
              </a>
            </GridItem>      
          </GridContainer>
          <GridContainer>
            <GridItem className="col-md-2 col-sm-12">
              <h5 className="text-center">Decreto 1069 de 2015 Sector Justicia y del Derecho</h5>
              <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174" 
                style={{marginBottom: "25px"}}
                role="button" className="btn btn-info"  target="_black" aria-disabled="true">
                <b style={{fontSize: "16px"}}>Ver información</b>
              </a>
            </GridItem>      
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
