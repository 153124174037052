/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function MisionVision() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/Medellin02.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title} >Misión y Visión</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>    
          <GridContainer className="ml-auto mr-auto">
            <GridItem md={11} >
              <h3 className="title-uppercase" style={{fontWeight:500, fontFamily: "'Noto Sans SC', sans-serif"}} >
                Misión:
              </h3>
              <div>
                <p className="my-4" style={{  fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
                  La Notaria  14 de Medellín tiene como misión prestar de manera adecuada, eficiente y transparente todos los servicios notariales existentes, dentro de una política de agilidad y excelente atención, con estándares de calidad, seguridad y legalidad que le proporcionen a nuestros usuarios la plena confiabilidad.
                </p>
              </div>
            </GridItem>
            <GridItem md={11}>
              <h3 className="title-uppercase" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
                Visión:
              </h3>
              <p className="my-4" style={{ fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
                La Notaria  14 de Medellín tiene como Visión ser una de las mejores opciones para los usuarios en el área metropolitana , posicionarse  de la mano del personal idóneo como una de las notarías más completas , ser la preferida por la calidad y legalidad de sus procesos y sus mejoras continuas , dándole así  certeza jurídica en todo momento.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
