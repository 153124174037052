/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Normativa() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/sections/Medellin02.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Normativa que regula la entidad</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{ paddingTop: "50px", paddingBottom: "30px" }}
        >
          <GridItem md={11}>
            <div className="card">
              <div className="card-body">
                <ul
                  className="mt-4"
                  style={{ fontFamily: "'Noto Sans SC', sans-serif" }}
                >
                  <li
                    className="list-group-item"
                    style={{ borderBottom: "1px solid #ccc5c5" }}
                  >
                    <a
                      href="https://www.suin-juriscol.gov.co/"
                      target='_black'
                      style={{
                        color: "black",
                        fontWeight: 500,
                        fontFamily: "'Noto Sans SC', sans-serif"
                      }}
                    >
                      <span className="texto"> Enlace a Sistema Único de Información Normativa – SUIN.</span>
                    </a>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html"
                        target="_blank"
                      >
                        <u>
                          http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174"
                        target="_blank"
                      >
                        <u>
                          https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_1260_1970.html#:~:text=El%20estado%20civil%20de%20una,asignaci%C3%B3n%20corresponde%20a%20la%20ley."
                        target="_blank"
                      >
                        <u>
                          http://www.secretariasenado.gov.co/senado/basedoc/decreto_1260_1970.html#:~:text=El%20estado%20civil%20de%20una
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.suin-juriscol.gov.co/viewDocument.asp?id=1478913"
                        target="_blank"
                      >
                        <u>
                          http://www.suin-juriscol.gov.co/viewDocument.asp?id=1478913
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1564_2012.html"
                        target="_blank"
                      >
                        <u>
                          http://www.secretariasenado.gov.co/senado/basedoc/ley_1564_2012.html
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.secretariasenado.gov.co/senado/basedoc/ley_0588_2000.html"
                        target="_blank"
                      >
                        <u>
                          http://www.secretariasenado.gov.co/senado/basedoc/ley_0588_2000.html
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.suin-juriscol.gov.co/viewDocument.asp?id=30019870"
                        target="_blank"
                      >
                        <u>
                          http://www.suin-juriscol.gov.co/viewDocument.asp?id=30019870
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1996_2019.html"
                        target="_blank"
                      >
                        <u>
                          http://www.secretariasenado.gov.co/senado/basedoc/ley_1996_2019.html
                        </u>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 500
                        }}
                        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=61836"
                        target="_blank"
                      >
                        <u>
                          https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=61836
                        </u>
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </GridItem>
        </div>
      </div>
    </div>
  );
}
