import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
const useStyles = makeStyles(aboutUsStyle);
// import Tramites from "/src/views/Molda/Tramites.js";
// core components

// sections for this page
// import Politica from "./Informacion/Informacion.js";


function RendicionCuenta() {
    const classes = useStyles();
    // const [iconTabs, setIconTabs] = React.useState("1");
    // const [Modal3, setModal3] = React.useState(false);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <div>
            <Parallax image={require("../../assets/img/sections/Medellin02.jpg")} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto,
                                classes.textCenter
                            )}
                        >
                            <h1 className={classes.title}>Rendición de cuentas</h1>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} >
                    <h4>Rendición de cuentas</h4>
                </div>
            </div>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <Card>
                            <CardBody>
                                <div className="title">
                                    <h1 className="text-justify title" style={{ color: "#031425" }}>
                                        <br></br>
                                        <br></br>
                                    </h1>
                                    <h3 className="text-justify title" style={{ color: "#031425" }}>
                                        <b>Rendición de cuentas</b><br></br>
                                    </h3>

                                </div>
                                <p className="text-justify" style={{ fontSize: "14px", color: "#68696F", fontFamily: "serif" }}>
                                    <b>La rendición de cuentas</b> es el proceso conformado por un conjunto de normas, procedimientos, metodologías,
                                    estructuras, prácticas y resultados mediante los que las entidades de la administración pública del nivel nacional
                                    y territorial y los servidores públicos informan, explican y dan a conocer los resultados de su gestión a los
                                    ciudadanos, la sociedad civil, otras entidades públicas y a los organismos de control a partir de la promoción
                                    del diálogo. En este sentido, las entidades deben atender lo dispuesto por el Manual único de rendición de
                                    cuentas (MURC)10, guía de obligatoria observancia para las entidades públicas.*
                                    <br></br>
                                    <br></br>
                                    <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        <b style={{ color: '#045FB4' }}>
                                            * Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de: https://www.funcionpublica.gov.co/documents/
                                        </b>
                                    </a>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    );

}

export default RendicionCuenta;