import React from "react";

// @material-ui/icons
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import Image from "@material-ui/icons/Image";
import {Dashboard} from "@material-ui/icons";



// Vistas Sinfony
import Cotizador2 from "views/Cotizador2/CotizadorWeb.js";
import Home from "views/PresentationPage/PresentationPage.js";
import Tramites from "views/Tramites/Tramites.js";
import FormQuejas from "views/Transparencia/FormQuejas.js";
import SectionTransparencia from "views/Transparencia/Transparencia.js";
import PoliticasDatosPersonales from "views/Transparencia/PoliticasDatosPersonales";
import TerminosCondiciones from "views/Transparencia/TerminosCondiciones";
import MapaSitio from "views/Transparencia/MapaSitio";
import PoliticasDerechosAutor from "views/Transparencia/PoliticasDerechosAutor";
import PoliticasPrivacidadWeb from "views/Transparencia/PoliticasPrivacidadWeb";
import CertificadoAccesibilidad from "views/Transparencia/CertificadoAccesibilidad";
import Notario from "views/Transparencia/Notario.js";
import InfoAdicional from "views/Transparencia/InfoAdicional.js";
import MisionVision from "views/Transparencia/MisionVision.js";
import Procesos from "views/Transparencia/Procesos.js";
import Organigrama from "views/Transparencia/Organigrama.js";
import Normativa from "views/Transparencia/Normativa.js";
import InfoTramites from "views/Transparencia/InfoTramites.js";
import Tarifas from "views/Transparencia/Tarifas.js";
import Web1 from "views/Transparencia/web1.js";
import Web2 from "views/Transparencia/web2.js";
import InfoAdolescentes from "views/Transparencia/InfoAdolescentes.js";
import InfOtros from "views/Transparencia/InfOtros.js";
import InfoMujeres from "views/Transparencia/InfoMujeres.js";
import Participa from "views/menu-participa/Participa.js";
import Diagnostico from "views/menu-participa/diagnostico-identificacion-problemas.js";
import Planeacion from "views/menu-participa/planeacion-presupuesto-participativo.js";
import Rendicion from "views/menu-participa/rendicion-cuenta.js";
import Consulta from "views/menu-participa/consulta-ciudadana";
import Control from "views/menu-participa/control-social";
import Colaboracion from "views/menu-participa/colaboracion-innovacion-abierta";
import AfectarPublico from "views/Transparencia/AfectarPublico";
import GestionDocumental from "views/Transparencia/GestionDocumental.js"
import RetencionDocumental from "views/Transparencia/RetencionDocumental.js";
import GestionInformacion from "views/Transparencia/GestionInformacion.js"
import ClasificadaReservada from "views/Transparencia/ClasificadaReservada.js"
import TomaDecisiones from "views/Transparencia/TomaDecisiones.js"
import PageLayout from "layouts/Page.js";
import MapaProcesos from "views/Transparencia/MapaProcesos.js"

export const newRoutes = [
  {
  path: "page",
    name: "Regresar Página web",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    element: <PageLayout></PageLayout>,
    layout: "/page",
    children:[ 
      {
        path: "home",
        name: "Regresar Página web",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Home></Home>,
        layout: "/page"        
      },
      {
        path: "Cotizador2",
        name: "Cotizador",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        element: <Cotizador2></Cotizador2>,
        layout: "/page"
      },
      {
        path: "home",
        name: "Home",
        rtlName: "عالتسعير",
        mini: "H",
        rtlMini: "ع",
        element: <Home></Home>,
        layout: "/page"
      },
      {
        path: "Tramites",
        name: "Trámites",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Tramites></Tramites>,
        layout: "/page"
      },
      {
        path: "pqrs",
        name: "pqrs",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <FormQuejas></FormQuejas>,
        layout: "/page"
      },
      {
        path: "TomaDecisiones",
        name: "TomaDecisiones",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <TomaDecisiones></TomaDecisiones>,
        layout: "/page"
      },
      {
        path: "transparencia/:modalNombre",
        name: "transparencia",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <SectionTransparencia></SectionTransparencia>,
        layout: "/page"
      },
      {
        path: "participa",
        name: "Participa",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Participa></Participa>,
        layout: "/page"
      },
      {
        path: "participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas",
        name: "ParticipacionDiagnostico",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Diagnostico></Diagnostico>,
        layout: "/page"
      },
      {
        path: "planeacion-y-presupuesto-participativo",
        name: "planeacion",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Planeacion></Planeacion>,
        layout: "/Planeacion"
      },
      {
        path: "consulta-ciudadana",
        name: "consultaCiudadana",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Consulta></Consulta>,
        layout: "/page"
      },
      {
        path: "rendicion-de-cuentas",
        name: "rendicion",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Rendicion></Rendicion>,
        layout: "/page"
      },
      {
        path: "control-social",
        name: "controlSocial",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Control></Control>,
        layout: "/page"
      },
      {
        path: "colaboracion-e-innovacion-abierta",
        name: "colaboracion",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Colaboracion></Colaboracion>,
        layout: "/page"
      },
      {
        path: "PoliticasDatosPersonales",
        name: "PoliticasDatosPersonales",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <PoliticasDatosPersonales></PoliticasDatosPersonales>,
        layout: "/page"
      },
      {
        path: "TerminosCondiciones",
        name: "TerminosCondiciones",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <TerminosCondiciones></TerminosCondiciones>,
        layout: "/page"
      },
      {
        path: "MapaSitio",
        name: "MapaSitio",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <MapaSitio></MapaSitio>,
        layout: "/page"
      },
      {
        path: "PoliticasDerechosAutor",
        name: "PoliticasDerechosAutor",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <PoliticasDerechosAutor></PoliticasDerechosAutor>,
        layout: "/page"
      },
      {
        path: "PoliticasPrivacidadWeb",
        name: "PoliticasPrivacidadWeb",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <PoliticasPrivacidadWeb></PoliticasPrivacidadWeb>,
        layout: "/page"
      },
      {
        path: "CertificadoAccesibilidad",
        name: "CertificadoAccesibilidad",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <CertificadoAccesibilidad></CertificadoAccesibilidad>,
        layout: "/page"
      },
      {
        path: "Notario",
        name: "Notario",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Notario></Notario>,
        layout: "/page"
      },
      {
        path: "InfoAdicional",
        name: "InfoAdicional",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <InfoAdicional></InfoAdicional>,
        layout: "/page"
      },
      {
        path: "InfoAdolescentes",
        name: "InfoAdolescentes",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <InfoAdolescentes></InfoAdolescentes>,
        layout: "/page"
      },
      {
        path: "InfoMujeres",
        name: "InfoMujeres",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <InfoMujeres></InfoMujeres>,
        layout: "/page"
      },
      {
        path: "InfOtros",
        name: "InfOtros",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <InfOtros></InfOtros>,
        layout: "/page"
      },
      {
        path: "actividades",
        name: "Actividades",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <actividades></actividades>,
        layout: "/page"
      },
      {
        path: "MisionVision",
        name: "MisionVision",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <MisionVision></MisionVision>,
        layout: "/page"
      },
      {
        path: "Procesos",
        name: "Procesos",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Procesos></Procesos>,
        layout: "/page"
      },
      {
        path: "Organigrama",
        name: "Organigrama",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Organigrama></Organigrama>,
        layout: "/page"
      },
      {
        path: "Normativa",
        name: "Normativa",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Normativa></Normativa>,
        layout: "/page"
      },
      {
        path: "InfoTramites",
        name: "InfoTramites",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <InfoTramites></InfoTramites>,
        layout: "/page"
      },
      {
        path: "Tarifas",
        name: "Tarifas",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Tarifas></Tarifas>,
        layout: "/page"
      },
      {
        path: "Web1",
        name: "Web1",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Web1></Web1>,
        layout: "/page"
      },
      {
        path: "Web2",
        name: "Web2",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <Web2></Web2>,
        layout: "/page"
      },
      {
        path: "AfectarPublico",
        name: "AfectarPublico",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <AfectarPublico></AfectarPublico>,
        layout: "/page"
      },
      {
        path: "GestionDocumental",
        name: "GestionDocumental",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <GestionDocumental></GestionDocumental>,
        layout: "/page"
      },
      {
        path: "ClasificadaReservada",
        name: "ClasificadaReservada",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <ClasificadaReservada></ClasificadaReservada>,
        layout: "/page"
      },
      {
        path: "RetencionDocumental",
        name: "RetencionDocumental",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <RetencionDocumental></RetencionDocumental>,
        layout: "/page"
      },
      {
        path: "GestionInformacion",
        name: "GestionInformacion",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <GestionInformacion></GestionInformacion>,
        layout: "/page"
      },
      {
        path: "MapaProcesos",
        name: "MapaProcesos",
        rtlName: "لوحة القيادة",
        icon: Dashboard,
        element: <MapaProcesos></MapaProcesos>,
        layout: "/page"
      }
    ]
  },  
  { 
    path: "*",
    name: "Regresar Página web",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    element: <PageLayout></PageLayout>,
    layout: "/page",
    children:[{ 
      path: "*",
      name: "Regresar Página web",
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      element: <Home></Home>,
      layout: "/page",
    }]
  }
];

