/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Parallax from "componentsPage/Parallax/Parallax.js";
import GridContainer from "componentsPage/Grid/GridContainer.js";
import GridItem from "componentsPage/Grid/GridItem.js";
// sections for this page
//import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionOffice from "views/PresentationPage/Sections/SectionOffice.js"
import SectionProjects from "views/PresentationPage/Sections/SectionProjects.js"
import SectionContacts from "./Sections/SectionContacts.js";
import Logos from "views/Transparencia/Logos.js";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);


export default function PresentationPage() {
  React.useEffect(() => {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener("scroll", updateView);
    updateView();
    return function cleanup() {
      window.removeEventListener("scroll", updateView);
    };
  });
  const updateView = () => {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");
    
    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  const classes = useStyles();
  return (
    <div> 
      <Parallax
        image={require("assets/img/MDE0.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}></GridItem>
            <GridItem>
              <div className={classes.brand}>
                <h1>
                  Notaría 14
                  <span className={classes.proBadge}>MEDELLÍN</span>
                </h1>
                <h2 className={classes.title}>
                  Servicios notariales
                </h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionProjects id="projects"/>
        <div className={classes.container}>
        <SectionOffice id="office"/>        
        </div>        
        <SectionContacts id="contacts" />
        <Logos id="logos"  />
      </div>
      
      <nav id="cd-vertical-nav">
        <ul>          
          <li>
            <a
              href="#description"
              data-number="1"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("description");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Descripción</span>
            </a>
          </li>
          <li>
            <a
              href="#projects"
              data-number="2"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("projects");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Aplicaciones</span>
            </a>
          </li>
          <li>
            <a
              href="#office"
              data-number="3"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("office");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Oficinas</span>
            </a>
          </li>
          <li>
            <a
              href="#contacts"
              data-number="4"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("contacts");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Contactos</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
