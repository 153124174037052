import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
import {newRoutes} from "./newRoutes.js";
// import PageLayout from "layouts/Page.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import 'assets/css/material-dashboard.css';

// const hist = createBrowserHistory();
const router = createBrowserRouter(newRoutes);

ReactDOM.render(
  
  //<BrowserRouter>
  //  <Provider store={store}>
  //  <Routes>
  //    <Route path="/page" component={PageLayout}/>
  //    <Link from="/" to="/page/home" />  
  //  </Routes>
  //  </Provider>
  //</BrowserRouter>,
    <Provider store={store}>
      <RouterProvider router={router}>
      </RouterProvider>
    </Provider>,
  document.getElementById("root")
);
